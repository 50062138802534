import { DateTime } from 'luxon';
import { PropsWithChildren, ReactElement } from 'react';
import colors from '../../colors';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { MobileDatePicker } from "@mui/x-date-pickers-pro";
import { CabIcon } from '@CabComponents/CabIcon';
import { IoCalendarClearOutline } from 'react-icons/io5';

interface MobileDayPickerProps {
  selectedDay: DateTime | null;
  orderedAvailableDates: DateTime[];
  onDaySelected: (daySelected: DateTime | null) => void;
  onCalendarDateChange?: (date: DateTime) => void;
  timezone?: string;
  slotsLoading: boolean;
}

const DatePickerTextField = (params: PropsWithChildren<TextFieldProps>) => (
  <TextField size='small' 
    {...params}
    sx={{ backgroundColor: colors.white900, width: '100%' }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <CabIcon size="small" Icon={IoCalendarClearOutline} />
        </InputAdornment>
      ),
    }}
  />
);

const MobileDayPicker = ({
  selectedDay, onDaySelected, orderedAvailableDates, onCalendarDateChange = () => undefined, timezone, slotsLoading
}: MobileDayPickerProps): ReactElement => {
  const disableDate = (date: DateTime) => {
    return !orderedAvailableDates.find(d => d.hasSame(date, 'day'));
  };

  return <>
    <MobileDatePicker
      disablePast
      timezone={timezone}
      // minDate={orderedAvailableDates[0]}
      // maxDate={orderedAvailableDates[orderedAvailableDates.length - 1]}
      disableHighlightToday
      disabled={slotsLoading}
      openTo="day"
      value={selectedDay}
      shouldDisableDate={(date) => disableDate(date)}
      onChange={onDaySelected}
      slots={{
        textField: DatePickerTextField,
      }}
      onYearChange={(date: DateTime) => {
        // this is the only guaranteed way to make sure we get date with correct timezone
        const nextDate = DateTime.fromISO(date.toISODate() || "", { zone: timezone });
        onCalendarDateChange(nextDate);
      }}
      onMonthChange={(date: DateTime) => {
        // this is the only guaranteed way to make sure we get date with correct timezone
        const nextDate = DateTime.fromISO(date.toISODate() || "", { zone: timezone });
        onCalendarDateChange(nextDate);
      }}
      slotProps={{
        previousIconButton: slotsLoading === true ? { disabled: true } : undefined,
        nextIconButton: { disabled: slotsLoading === true ? true : undefined },
        day: {
          sx: (theme) => ({
            backgroundColor: colors.lavendar200,
            '&:hover, &:focus': {
              backgroundColor: colors.lavendar500,
            },
            '&.MuiPickersDay-root.Mui-selected': {
              backgroundColor: colors.lavendar950,
              '&:hover': {
                backgroundColor: colors.lavendar800,
              }
            },
            '&.MuiPickersDay-root.Mui-disabled': {
              backgroundColor: colors.white900,
            },
          }),
        },
      }}
    />
  </>;
};

export default MobileDayPicker;