import { DateTime } from 'luxon';
import { ReactElement } from 'react';
import colors from '../../colors';
import { Box } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers-pro';

interface StaticDayPickerProps {
  selectedDay: DateTime | null;
  noDefault?: boolean;
  orderedAvailableDates: DateTime[];
  onDaySelected: (daySelected: DateTime | null) => void;
  onCalendarDateChange?: (date: DateTime) => void;
  defaultYearMonth: [number, number]|null;
  timezone?: string;
  slotsLoading: boolean;
}

const StaticDayPicker = ({
  selectedDay, onDaySelected, orderedAvailableDates, noDefault, defaultYearMonth, timezone,
  onCalendarDateChange = () => undefined, slotsLoading
}: StaticDayPickerProps): ReactElement => {
  const disableDate = (date: DateTime) => {
    return !orderedAvailableDates.find(d => d.hasSame(date, 'day'));
  };

  const value = noDefault || !selectedDay ? null : selectedDay.setZone(timezone);
  const selectedDateIsDisabled = value ? disableDate(value) : false;

  return <Box data-testid="static-date-picker" sx={{
    '& .MuiPickersArrowSwitcher-root .MuiButtonBase-root': {
      backgroundColor: colors.lavendar200,
      '&:hover': {
        backgroundColor: colors.lavendar500
      },
      '&.Mui-disabled': {
        backgroundColor: 'inherit'
      }
    }
  }}>
    {defaultYearMonth && (
      <StaticDatePicker
        key={timezone}
        timezone={timezone}
        displayStaticWrapperAs='desktop'
        disablePast
        // minDate={orderedAvailableDates[0]}
        // maxDate={orderedAvailableDates[orderedAvailableDates.length - 1]}
        disableHighlightToday
        disabled={slotsLoading}
        openTo="day"
        onYearChange={(date: DateTime) => {
          // this is the only guaranteed way to make sure we get date with correct timezone
          const nextDate = DateTime.fromISO(date.toISODate() || "", { zone: timezone });
          onCalendarDateChange(nextDate);
          onDaySelected(null);
        }}
        onMonthChange={(date: DateTime) => {
          // this is the only guaranteed way to make sure we get date with correct timezone
          const nextDate = DateTime.fromISO(date.toISODate() || "", { zone: timezone });
          onCalendarDateChange(nextDate);
          onDaySelected(null);
        }}
        value={value}
        shouldDisableDate={(date) => date ? disableDate(date) : true}
        onChange={d => onDaySelected(d)}
        referenceDate={DateTime.fromObject({ year: defaultYearMonth[0], month: defaultYearMonth[1] })}
        slotProps={{
          previousIconButton: slotsLoading === true ? { disabled: true } : undefined,
          nextIconButton: { disabled: slotsLoading === true ? true : undefined },
          day: {
            sx: (theme) => ({
              backgroundColor: colors.lavendar200,
              '&:hover, &:focus': {
                backgroundColor: colors.lavendar500,
              },
              '&.MuiPickersDay-root.Mui-selected': {
                backgroundColor: colors.lavendar950,
                // this prevents the selected date becoming invisible when the date is disabled
                color: selectedDateIsDisabled ? colors.black700 : undefined,
                '&:hover': {
                  backgroundColor: colors.lavendar800,
                }
              },
              '&.MuiPickersDay-root.Mui-disabled': {
                backgroundColor: 'inherit',
              },
            }),
          },
        }}
      />
    )}
  </Box>;
};

export default StaticDayPicker;
